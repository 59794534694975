import React, { useEffect, useState, useCallback } from 'react'
import { UserAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import { X, ArrowLeft,User } from 'lucide-react'

export default function UserProfile() {
  const [userAltura, setUserAltura] = useState(0)
  const [userPeso, setUserPeso] = useState(0)
  const [userAge, setUserAge] = useState('')
  const [genero, setGenero] = useState('')
  const [aguaRecomendada, setAguaRecomendada] = useState(0)
  const [msgSave, setMsgSave] = useState('')
  const [alteracao, setAlteracao] = useState(false)
  const [bmi, setBMI] = useState(0)
  const [status, setStatus] = useState('')

  const { retornaDados, salvarDados } = UserAuth()
  const navigate = useNavigate()

  const calcularAguaNecessaria = useCallback(() => {
    const agua = (userPeso ? 0.035 * userPeso : 0).toFixed(2)
    setAguaRecomendada(agua)
  }, [userPeso])

  const getPointerPosition = () => {
    const minBMI = 15
    const maxBMI = 40
    const bmiValue = parseFloat(bmi)
    if (bmiValue < minBMI) return 0
    if (bmiValue > maxBMI) return 100
    return ((bmiValue - minBMI) / (maxBMI - minBMI)) * 100
  }

  useEffect(() => {
    retornaDados()
      .then((array) => {
        if (array !== null) {
          setUserPeso(array[0])
          setUserAltura(array[1])
          const bmiValue = (array[0] / (array[1] * array[1])).toFixed(1)
          setBMI(bmiValue)

          if (bmiValue < 18.5) setStatus('Abaixo do peso')
          else if (bmiValue >= 18.5 && bmiValue < 24.9) setStatus('Peso Saúdavel')
          else if (bmiValue >= 25 && bmiValue < 29.9) setStatus('Acima do peso')
          else setStatus('Obesidade')
        } else {
          console.log('Sem dados ainda')
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error)
      })
  }, [retornaDados])

  useEffect(() => {
    calcularAguaNecessaria()
  }, [userPeso, calcularAguaNecessaria])

  const handleAlterar = () => {
    setAlteracao(!alteracao)
    setMsgSave('')
  }

  const handleSalvar = async () => {
    const imc = userPeso / (userAltura * userAltura)
    try {
      await salvarDados(userPeso, userAltura, parseInt(imc))
    } catch {
    } finally {
      setMsgSave('Dados Salvos com Sucesso!')
      navigate(window.location.pathname)
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
    <div className="sticky top-0 z-10 bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <button
            onClick={() => navigate('/home')}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="w-5 h-5 mr-1" />
            Voltar
          </button>
          <User className="w-6 h-6 text-gray-800" />
        </div>
      </div>
    </div>
      <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-lg overflow-hidden border border-gray-200">
          <div className="p-6 sm:p-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
              <div className="bg-white rounded-lg p-6 transition-all duration-300 hover:shadow-md border border-gray-200">
                <div className="flex items-center mb-4">
                  <span className="text-4xl mr-2">⚖️</span>
                  <h2 className="text-xl font-semibold text-black">Peso</h2>
                </div>
                <p className="text-3xl font-bold text-black">{userPeso} kg</p>
              </div>
              <div className="bg-white rounded-lg p-6 transition-all duration-300 hover:shadow-md border border-gray-200">
                <div className="flex items-center mb-4">
                  <span className="text-4xl mr-2">📏</span>
                  <h2 className="text-xl font-semibold text-black">Altura</h2>
                </div>
                <p className="text-3xl font-bold text-black">{userAltura} m</p>
              </div>
            </div>

            <div className="bg-white rounded-lg p-6 mb-8 transition-all duration-300 hover:shadow-md border border-gray-200">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-2">💧</span>
                <h2 className="text-xl font-semibold text-black">Água Recomendada</h2>
              </div>
              <p className="text-3xl font-bold text-black">{aguaRecomendada} L/dia</p>
            </div>

            {bmi && (
              <div className="bg-white rounded-lg p-6 mb-8 border border-gray-200">
                <div className="flex items-center mb-4">
                  <span className="text-4xl mr-2">📊</span>
                  <h2 className="text-xl font-semibold text-black">IMC</h2>
                </div>
                <p className="text-2xl font-bold text-black mb-4">{bmi}</p>
                <p className={`text-lg font-medium mb-4 ${
                  status === 'Abaixo do peso' ? 'text-yellow-600' :
                  status === 'Peso Saúdavel' ? 'text-green-600' :
                  status === 'Acima do peso' ? 'text-orange-600' :
                  'text-red-600'
                }`}>
                  {status}
                </p>
                <div className="relative w-full h-6 bg-gray-200 rounded-full overflow-hidden">
                  <div 
                    className="absolute top-0 left-0 h-full rounded-full bg-gradient-to-r from-green-500 via-yellow-500 to-red-500 transition-all duration-500 ease-in-out" 
                    style={{ width: `${getPointerPosition()}%` }}
                  ></div>
                  <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center px-2 text-xs font-bold text-white">
                    <span>Abaixo</span>
                    <span>Normal</span>
                    <span>Acima</span>
                    <span>Obeso</span>
                  </div>
                </div>
                <div className="flex justify-between text-xs text-gray-600 mt-1">
                  <span>15</span>
                  <span>18.5</span>
                  <span>25</span>
                  <span>30</span>
                  <span>35+</span>
                </div>
              </div>
            )}

            <div className="flex justify-center space-x-4">
              <button
                onClick={handleAlterar}
                className="px-6 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors duration-300"
              >
                {alteracao ? 'Cancelar' : 'Atualizar Dados'}
              </button>
              <button
                onClick={() => navigate('/dieta')}
                className="px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
              >
                Minhas Dietas
              </button>
            </div>

            {msgSave && (
              <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-md text-center">
                {msgSave}
              </div>
            )}
          </div>
        </div>

        {alteracao && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md relative">
              <button
                onClick={handleAlterar}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
              <h2 className="text-2xl font-bold mb-6 text-center text-black">Atualizar Dados</h2>
              <div className="space-y-4">
                <div>
                  <label htmlFor="weight" className="block text-sm font-medium text-gray-700 mb-1">
                    Peso (kg)
                  </label>
                  <input
                    type="number"
                    id="weight"
                    value={userPeso}
                    onChange={(e) => setUserPeso(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                    placeholder="Digite seu Peso"
                  />
                </div>
                <div>
                  <label htmlFor="height" className="block text-sm font-medium text-gray-700 mb-1">
                    Altura (m)
                  </label>
                  <IMaskInput
                    mask="0.00"
                    type="text"
                    id="height"
                    value={userAltura}
                    onChange={(e) => setUserAltura(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                    placeholder="Digite sua Altura: Ex: 1.69"
                  />
                </div>
                <div>
                  <label htmlFor="age" className="block text-sm font-medium text-gray-700 mb-1">
                    Idade
                  </label>
                  <input
                    type="number"
                    id="age"
                    value={userAge}
                    onChange={(e) => setUserAge(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                    placeholder="Digite sua Idade"
                  />
                </div>
                <div>
                  <label htmlFor="genero" className="block text-sm font-medium text-gray-700 mb-1">
                    Gênero
                  </label>
                  <select
                    id="genero"
                    value={genero}
                    onChange={(e) => setGenero(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    <option value="">Informe seu gênero</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </select>
                </div>
                <button
                  onClick={handleSalvar}
                  className="w-full px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-300"
                >
                  SALVAR
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}