import React from 'react';

const ButtonSwitch = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between', // Ajustando o posicionamento para manter um botão à esquerda e outro centralizado
      alignItems: 'center',
      background: '#F6F8FA', // Fundo conforme especificado
      padding: '10px 20px', // Ajuste no padding para maior espaço em torno dos botões
      borderRadius: '30px', // Bordas arredondadas no container
      width: '100%', // Mantendo a largura ajustada para 95%
      height: '50px', // Altura definida para reduzir o espaço vertical
      margin: '0 auto', // Centralizando o container
      position: 'relative' // Usando posicionamento relativo para o alinhamento preciso
    }}>
      <button
        style={{
          backgroundColor: '#4CAF50', // Cor verde no botão 'Imperial'
          color: 'white',
          border: 'none',
          padding: '10px 54px', // Padronizando o padding para igualar
          textAlign: 'center',
          textDecoration: 'none',
          marginLeft:'-20px',
          display: 'inline-block',
          fontSize: '16px', // Fonte para destaque
          cursor: 'pointer',
          borderRadius: '20px', // Bordas arredondadas no botão
          position: 'absolute', // Posicionamento absoluto
          left: '20px' // Alinhado à esquerda com espaço
        }}
      >
        Medidas
      </button>

      <button
        style={{
          backgroundColor: 'transparent', // Fundo transparente
          color: 'gray',
          border: 'none',
          padding: '10px 20px', // Padronizando o padding para igualar
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px',
          marginLeft:'94px',

          cursor: 'pointer',
          borderRadius: '20px',
          position: 'absolute', // Posicionamento absoluto
          left: '50%', // Centralizando
          transform: 'translateX(-50%)' // Ajuste fino para centralização exata
        }}
      >
        
      </button>
    </div>
  );
};

export default ButtonSwitch;
