export default function ComoFunciona() {
    return (
        <div className="text-center p-4 bg-white rounded-2xl shadow-lg" style={{ width: '95%', margin: '0 auto', border: '1.3px solid #D9D8D8' }}>
          <div className="flex justify-center mb-4">
            <div className="w-14 h-14 rounded-full bg-green-100 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-8 h-8 text-green-600"
              >
                <circle cx="12" cy="12" r="10" />
                <polyline points="12 6 12 12 16 14" />
              </svg>
            </div>
          </div>
          <h2 className="text-2xl font-bold text-center mb-4">Como funciona?</h2>
          <p className="text-gray-600 text-sm text-center">
            O valor é pago apenas uma vez via PIX. No cartão, é uma assinatura recorrente com vários benefícios, 
            incluindo consultas semanais com a nutricionista. Você pode cancelar quando quiser. <br />
            Sempre baixe sua dieta em pdf antes de gerar outra, pois será substituída.
          </p>
        </div>
    )
  }