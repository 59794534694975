import React, { useState, useEffect, useCallback } from 'react';
import { UserAuth } from '../../context/AuthContext';
import CreditCard from '../../img/cartaodecredito.png'

function criarLinkPagamento(linkexterno, token) {
  return `${linkexterno}?utm_content=${token}&utm_source=${token}`;
}

function PagamentoComponent(linkexterno) {
  const [linkPagamento, setLinkPagamento] = useState(null);
  const { user } = UserAuth();
 
  const updateLinkPagamento = useCallback(() => {
    if (user && user.uid) {
      const link = criarLinkPagamento(linkexterno.linkexterno, user.uid);
      setLinkPagamento(link);
    } else {
      setLinkPagamento(null);
    }
  }, [user, linkexterno.linkexterno]);

  useEffect(() => {
    updateLinkPagamento();
  }, [updateLinkPagamento]);

  if (!linkPagamento) {
    return <p>Carregando...</p>;
  } // Dependência token

  return (
    <div>
     <a className=' no-underline' href={linkPagamento}> <button className="flex bg-white border-1 mb-3 border-gray-300 text-gray-700  px-4 py-3 rounded-lg shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
  <div className='flex'>
  <div className="flex items-center justify-center bg-white border-1 border-gray-300 text-white rounded-full p-2 w-[50px] mx-auto">
   <img src={CreditCard} alt='Cartao de Credito' className='w-full'/>
  </div> 
  <div className='text-left flex flex-col justify-center'>
  <span className="font-medium text-sm ml-4  text-black">
    Cartão de Crédito
    <span className="text-xs font- mt-1 text-gray-500 block">
     Aprovação na hora
    </span>
  </span>
  </div>
  </div>
</button></a>
    </div>
  );
}

export default PagamentoComponent;