import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom'; // Importe o Link do React Router

export default function Calorias() {
  const [formData, setFormData] = useState({
    idade: '',
    sexo: '',
    peso: '',
    altura: '',
    atividade: '',
  });
  const [resultadoCalorias, setResultadoCalorias] = useState('');
  const [todosCamposPreenchidos, setTodosCamposPreenchidos] = useState(false);
  const resultRef = useRef(null);

  useEffect(() => {
    if (resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [resultadoCalorias, todosCamposPreenchidos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateCalories = () => {
    let bmr;
    const idade = parseInt(formData.idade, 10);
    const peso = parseFloat(formData.peso);
    const altura = parseFloat(formData.altura); // Altura já está em cm
    const { sexo, atividade } = formData;

    if (sexo === 'masculino') {
      bmr = 88.362 + (13.397 * peso) + (4.799 * altura) - (5.677 * idade);
    } else if (sexo === 'feminino') {
      bmr = 447.593 + (9.247 * peso) + (3.098 * altura) - (4.330 * idade);
    }

    let factor;
    switch (atividade) {
      case 'sedentario':
        factor = 1.2;
        break;
      case 'leve':
        factor = 1.375;
        break;
      case 'moderado':
        factor = 1.55;
        break;
      case 'ativo':
        factor = 1.725;
        break;
      case 'muito_ativo':
        factor = 1.9;
        break;
      default:
        factor = 1.2; // Consideramos sedentário por padrão se não houver seleção
    }

    const calorias = bmr * factor;
    return calorias;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { idade, sexo, peso, altura, atividade } = formData;
    if (!idade || !sexo || !peso || !altura || !atividade) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    if (parseInt(idade, 10) <= 0 || parseFloat(peso) <= 0 || parseFloat(altura) <= 0) {
      alert('Por favor, insira valores positivos para idade, peso e altura.');
      return;
    }

    const calorias = calculateCalories();
    setResultadoCalorias(calorias.toFixed(2));
    setTodosCamposPreenchidos(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-8">
      <div className="relative max-w-md w-full mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Calcule suas Calorias Diárias 🔥
        </h2>
        <p className="text-sm text-gray-600 mb-6 text-center">
          Invista na sua saúde! Use a Nutri Inteligente 🥦
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col">
            <label htmlFor="idade" className="mb-2 text-sm font-medium text-gray-900">
              Idade
            </label>
            <input
              type="number"
              name="idade"
              id="idade"
              value={formData.idade}
              onChange={handleChange}
              className="px-4 py-2 border rounded-lg focus:ring-green-500 focus:border-green-500"
            />
          </div>
  
          <div className="flex flex-col">
            <label htmlFor="sexo" className="mb-2 text-sm font-medium text-gray-900">
              Sexo
            </label>
            <select
              name="sexo"
              id="sexo"
              value={formData.sexo}
              onChange={handleChange}
              className="px-4 py-2 border rounded-lg focus:ring-green-500 focus:border-green-500"
            >
              <option value="">Selecione</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
            </select>
          </div>
  
          <div className="flex flex-col">
            <label htmlFor="peso" className="mb-2 text-sm font-medium text-gray-900">
              Peso (Somente Números)
            </label>
            <input
              type="text"
              name="peso"
              id="peso"
              value={formData.peso}
              onChange={handleChange}
              pattern="^\d+(\.\d{1,2})?$"
              title="Por favor, insira o peso em quilogramas usando apenas números e, se necessário, um ponto decimal. Exemplo: 70 ou 70.5"
              className="px-4 py-2 border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
  
          <div className="flex flex-col">
            <label htmlFor="altura" className="mb-2 text-sm font-medium text-gray-900">
              Altura (cm)
            </label>
            <InputMask
              mask="999"
              maskChar={null}
              type="text"
              name="altura"
              id="altura"
              value={formData.altura}
              onChange={handleChange}
              className="px-4 py-2 border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
  
          <div className="flex flex-col">
            <label htmlFor="atividade" className="mb-2 text-sm font-medium text-gray-900">
              Nível de Atividade
            </label>
            <select
              name="atividade"
              id="atividade"
              value={formData.atividade}
              onChange={handleChange}
              className="px-4 py-2 border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Selecione</option>
              <option value="sedentario">Sedentário</option>
              <option value="leve">Levemente ativo</option>
              <option value="moderado">Moderadamente ativo</option>
              <option value="ativo">Ativo</option>
              <option value="muito_ativo">Muito ativo</option>
            </select>
          </div>
  
          <button
            type="submit"
            className="w-full py-3 bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-white font-semibold rounded-lg"
          >
            Calcular Calorias
          </button>
        </form>
  
        {/* Overlay de Resultado */}
        {resultadoCalorias && todosCamposPreenchidos && (
          <div
            ref={resultRef}
            tabIndex={-1}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="relative w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
                Resultado 🎯
              </h2>
              <p className="text-lg text-gray-700 mb-4 text-center">
                Sua ingestão diária recomendada de calorias é de:
              </p>
              <span className="block text-3xl font-bold text-gray-900 text-center">
                {resultadoCalorias} calorias 🔥
              </span>
              <p className="mt-4 text-sm text-gray-600 text-center">
  <strong> Dependendo do seu objetivo,</strong> como perda de peso ou ganho de massa muscular, a quantidade de calorias <strong>pode ser menor.</strong> Monte um plano personalizado. Clique em <strong>Montar Minha Dieta</strong> para gerar um plano de acordo com suas necessidades.
</p>

              <Link
  to="/home"
  className="block w-full py-3 mt-4 bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-white font-semibold rounded-lg text-center no-underline"
>
  Montar Dieta Personalizada
</Link>

            </div>
          </div>
        )}
      </div>
    </div>
  );
}  