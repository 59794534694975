import React, { useState } from 'react';
import { FaCopy, FaCheck, FaChevronLeft, FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function IndiqueEGanhe() {
  const [linkCopiado, setLinkCopiado] = useState(false);
  const linkIndicacao = 'https://nutriinteligente.online';
  const mensagemIndicacao = "Criei minha dieta personalizada na Nutri Inteligente e estou adorando! Agora, preciso que você e mais 5 amigos façam uma compra para que eu ganhe uma consulta com a nutricionista. Confira o link:";
  const navigate = useNavigate();

  const copiarLink = () => {
    const textoCompleto = mensagemIndicacao + linkIndicacao;
    navigator.clipboard.writeText(textoCompleto).then(() => {
      setLinkCopiado(true);
      setTimeout(() => setLinkCopiado(false), 3000);
    });
  };

  const styles = {
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#f5f5f5',
    },
    backButton: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      color: '#333',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      padding: '10px',
    },
    backIcon: {
      marginRight: '8px',
    },
    container: {
      width: '100%',
      maxWidth: '400px',
      padding: '32px',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      fontFamily: 'Arial, sans-serif',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
    header: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '24px',
    },
    subtitle: {
      fontSize: '16px',
      color: '#666',
      marginBottom: '32px',
    },
    steps: {
      marginBottom: '32px',
      display: 'inline-block',
      textAlign: 'left',
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    stepNumber: {
      width: '24px',
      height: '24px',
      backgroundColor: '#f0f0f0',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '12px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#333',
    },
    stepText: {
      fontSize: '14px',
      color: '#333',
    },
    inputContainer: {
      display: 'flex',
      marginBottom: '24px',
      justifyContent: 'center',
    },
    input: {
      width: '60%',
      padding: '12px',
      fontSize: '14px',
      border: '1px solid #e0e0e0',
      borderRadius: '6px 0 0 6px',
      outline: 'none',
    },
    button: {
      padding: '12px 20px',
      backgroundColor: '#333',
      color: 'white',
      border: 'none',
      borderRadius: '0 6px 6px 0',
      cursor: 'pointer',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.3s',
    },
    buttonIcon: {
      marginRight: '8px',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <button style={styles.backButton} onClick={() => navigate('/home')}>
        <FaChevronLeft style={styles.backIcon} />
        <span>Voltar</span>
      </button>
      <div style={styles.container}>
        <h1 style={styles.header}>Indique e Ganhe 💚</h1>
        <p style={styles.subtitle}>
          Indique 5 amigos para criar uma dieta personalizada na Nutri Inteligente e ganhe uma consulta grátis com nossa nutricionista!
        </p>
        <div style={styles.steps}>
          <div style={styles.step}>
            <span style={styles.stepNumber}>1</span>
            <span style={styles.stepText}>Copie e compartilhe o link abaixo</span>
          </div>
          <div style={styles.step}>
            <span style={styles.stepNumber}>2</span>
            <span style={styles.stepText}>Seu amigo realiza uma compra</span>
          </div>
          <div style={styles.step}>
            <span style={styles.stepNumber}>3</span>
            <span style={styles.stepText}>Peça a eles os comprovante e nos envie.</span>
          </div>
          <div style={styles.step}>
            <span style={styles.stepNumber}>4</span>
            <span style={styles.stepText}>Ganhe uma consulta grátis!</span>
          </div>
        </div>
        <div style={styles.inputContainer}>
          <input 
            type="text" 
            value={linkIndicacao} 
            readOnly 
            style={styles.input}
          />
          <button onClick={copiarLink} style={styles.button}>
            {linkCopiado ? <FaCheck style={styles.buttonIcon} /> : <FaCopy style={styles.buttonIcon} />}
            {linkCopiado ? 'Copiado!' : 'Copiar'}
          </button>
        </div>
        <p className="text-sm text-green-600 bg-green-50 p-2 rounded-md flex items-center justify-center">
  <FaInfoCircle className="mr-2 text-base" />
  A consulta grátis será agendada após a confirmação da compra do seu indicado.
</p>
      </div>
    </div>
  );
}