export default function ComoReceberDieta() {
  return (
    <div className="text-center p-4 bg-white rounded-2xl shadow-lg" style={{ width: '95%', margin: '0 auto', border: '1.3px solid #D9D8D8' }}>
        <div className="flex justify-center mb-4">
          <div className="w-14 h-14 rounded-full bg-green-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-8 h-8 text-green-600"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" y1="15" x2="12" y2="3" />
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-bold text-center mb-4">Como receber a Dieta?</h2>
        <p className="text-gray-600 text-sm text-center">
          Após o pagamento, volte ao site e aguarde até 5 minutos para que sua dieta seja gerada. 
          Você poderá visualizá-la ou baixá-la em PDF.(Somente pelo google)
        </p>
      </div>
  )
}