import React, { useState } from 'react'
import { ArrowRight, Loader } from 'lucide-react'
import { motion } from 'framer-motion'

export default function DietSelector() {
  const [quantity, setQuantity] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const calculatePrice = (qty) => {
    let basePrice = 6.99
    let totalPrice = basePrice

    for (let i = 1; i < qty; i++) {
      if (i >= 10) {
        totalPrice += 1
      } else {
        totalPrice *= 1.22
      }
    }

    return totalPrice.toFixed(2)
  }

  const handleSliderChange = (event) => {
    setQuantity(Number(event.target.value))
  }

  const handleNavigation = (url) => {
    setIsLoading(true)
    // Simulate a delay before navigation
    setTimeout(() => {
      window.location.href = url
    }, 1500)
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-4xl">
        <h2 className="text-center text-4xl font-extrabold text-gray-900 mb-8">
          Escolha a Quantidade
        </h2>

        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-xl sm:px-10 border border-gray-200">
          <div className="space-y-8">
            <div className="text-center">
              <label htmlFor="quantity" className="block text-xl font-medium text-gray-700 mb-4">
                Quantidade de Dietas
              </label>
              <div className="flex flex-col items-center">
                <input
                  type="range"
                  id="quantity"
                  min="1"
                  max="50"
                  value={quantity}
                  onChange={handleSliderChange}
                  className="w-full max-w-md h-2 bg-gray-200 rounded-full appearance-none cursor-pointer"
                />
                <div className="mt-4 text-5xl font-bold text-gray-900">
                  {quantity}
                </div>
                <div className="mt-1 text-lg text-gray-500">
                  {quantity === 1 ? 'Dieta' : 'Dietas'}
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-6 py-6 rounded-xl border border-gray-200">
              <div className="flex justify-between items-center mb-4">
                <dt className="text-lg font-medium text-gray-500">Subtotal</dt>
                <dd className="text-xl font-medium text-gray-900">R$ {calculatePrice(quantity)}</dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex justify-between items-center">
                <dt className="text-xl font-semibold text-gray-900">Total</dt>
                <dd className="text-3xl font-bold text-gray-900">R$ {calculatePrice(quantity)}</dd>
              </div>
            </div>

            <div className="mt-8">
              <motion.button
                onClick={() => handleNavigation("https://nutriinteligentepro.online/signup-nutri")}
                className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-full shadow-sm text-lg font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 no-underline"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader className="animate-spin mr-2 h-5 w-5" />
                    Carregando...
                  </>
                ) : (
                  <>
                    Ir para o Site
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </>
                )}
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}