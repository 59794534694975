import React from 'react';
import PropTypes from 'prop-types';
import Listapdf from '../PDF/Lista.pdf';
import ReceitasPdf from '../PDF/ReceitasPdf';
import { useNavigate } from 'react-router-dom';
import RecomendaWhey from '../PDF/RecomendaWhey/WheyRecomenda';
import RecomendaCreatina from '../PDF/RecomendaCreatina/CreatinaRecomenda';
import RecomendaFrutas from '../PDF/ListaFrutas/RecomendaFrutas';
import { Info } from 'lucide-react';

export default function MenuDieta({ isOpen, toggleMenu }) {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate('/home');
  };

  const Compra = () => {
    navigate('/listacompras');
  };

  const CardWrapper = ({ children, icon, title, description, onClick, info }) => (
    <div 
      className="bg-white rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center w-full cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full mb-3">
        <span className="text-2xl">{icon}</span>
      </div>
      <h3 className="text-lg font-semibold text-gray-700 mb-1">{title}</h3>
      <p className="text-sm text-gray-600 mb-3">{description}</p>
      {children}
      {info && (
      <div className="text-xs text-gray-400 flex items-center mt-2">
                <Info size={12} className="mr-1" />
                PDF disponível apenas no Google Chrome e Safari.
            </div>
        )}
    </div>
  );

  return (
    <div className="bg-gray-100 p-4 sm:p-6 rounded-xl shadow-lg mx-auto">
      <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-6 text-center">Adicionais Nutricionais</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <CardWrapper 
          icon="📋" 
          title="Lista de Substituição" 
          description="Baixe a lista de alimentos substitutos"
          onClick={() => {
            const link = document.createElement('a');
            link.href = Listapdf;
            link.download = "ListaSubstituição.pdf";
            link.click();
          }}
          info={true}
          
        />
        

          <RecomendaWhey />

          <RecomendaCreatina />

          <RecomendaFrutas />

          <ReceitasPdf />

        <CardWrapper
          icon="🛒"
          title="Lista de Compra"
          description="Acesse sua lista de compras personalizada"
          onClick={Compra}
        />

        <CardWrapper
          icon="🏠"
          title="Página Inicial"
          description="Voltar para a página principal"
          onClick={Voltar}
        />
      </div>
    </div>
  );
}

MenuDieta.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};