import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../Menu/HamburgerMenu.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ImageMenu from '../../../img/PerfilMenu-removebg-preview.png';
import { useNavigate } from 'react-router-dom';


function HamburgerMenuLista({ isOpen, toggleMenu }) {
  
  const navigate = useNavigate()
  const Voltar = () => {
    navigate('/home')
  }

  const Compra = () => {
    navigate('/dieta')
  }
    
  return (
    <div className=' flex justify-start'>
      <div className={styles['menu-toggle']} onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} size='2x'  className='bg-[#17A34A] text-white m-0 p-2 rounded-[7px]'></FontAwesomeIcon>
        
        </div>
      {isOpen && (
        <div className={`${styles['hamburger-menu']} ${isOpen ? styles.open : ''} w-[80%] sm:w-2/4 lg:w-2/6`} style={{ background: 'transparent' }}>
          <div className={`${styles['close-button']} mt-3`} onClick={toggleMenu}>
            X
          </div>

          <nav className=''>
            <ul className=' my-0 flex flex-col justify-start lg:justify-start shadow-2xl bg-[#fff] h-[450px] sm:h-[450px] lg:h-[410px] rounded-lg items-center text-sm p-0'>

              {/* <img src={ImageMenu} className='w-[180px] h-[160px] lg:w-[120px] lg:h-[100px] p-0 m-0' alt="" /> */}

              <div className='flex flex-col justify-around w-full mt-3 '>
              <li className='border-bottom'>
                  <div className='flex items-center justify-evenly'>
                  <div className=' w-[50%] rounded-full '>
                  <img src={ImageMenu} className='w-[70px] h-[120px] p-0 m-0 mx-auto' alt="" />
                  </div>
                <div className=' h-[90px] flex flex-col py-2 items-center justify-center text-left'>
                  <h6 className='text-black w-full text-left'>Clique em Dieta abaixo para copiar sua dieta</h6>
                    </div>
                  </div>
                </li>
                
                <li className=''>
                   
               
                  <button className="flex border-bottom text-sm md:text-base items-center p-4 text-black w-full h-[50px] lg:h-[40px]  " onClick={Compra}>
                  <h1 className='pr-2 m-0'>📋</h1> Dieta
                  </button>
                  <button className="flex border-bottom text-sm md:text-base items-center p-4 text-black w-full h-[50px] lg:h-[40px]  " onClick={Voltar}>
                  <h1 className='pr-2 m-0'>🏠</h1> Página Inicial
                  </button>

                 
                 
              </li>
            
              </div>
            </ul>
          </nav>

        </div>
      )}
    </div>
  );

}

HamburgerMenuLista.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerMenuLista;
