import React, { useState, useEffect } from 'react';
import '../LoadingComponent/load.css';
import '../LoadingComponent/load.css';
import Frases from './RandomFrases/Frases';
import { useNavigate } from 'react-router-dom';

export default function LoadComponent() {
  const [seconds, setSeconds] = useState(150);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Pega o horário de início do localStorage ou define o horário atual
    const startTime = localStorage.getItem('startTime') || Date.now();
    localStorage.setItem('startTime', startTime);

    // Calcula o tempo restante baseado no horário de início
    const interval = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      const remainingTime = Math.max(150 - elapsedTime, 0);

      setSeconds(remainingTime);

      if (remainingTime <= 0) {
        setLoadingComplete(true);
        clearInterval(interval);
        localStorage.removeItem('startTime');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const progressPercentage = ((150 - seconds) / 150) * 100;

  const svgIcon = (
    <svg height="32" width="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#D1FAE5"></circle>
      <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
    </svg>
  );

  return (
    <div className="loader-unique">
      {loadingComplete ? (
        <div className="icon-and-text">
          {svgIcon}
          <div className="text-complete">Clique no botão abaixo para salvar a dieta.</div>
          <button className="save-button-unique" onClick={() => navigate('/dieta')}>Salvar Dieta</button>
        </div>
      ) : (
        <div className="loading-content">
          <span className="bg-green-100 text-green-600 text-lg font-semibold px-2.5 py-0.5 rounded">TEMPO ESTIMADO: 5 minutos</span><Frases />
          <div className="panWrapper-unique">
            <div className="pan-unique">
              <div className="fuud-unique"></div>
              <div className="panBase-unique"></div>
              <div className="panHandle-unique"></div>
            </div>
            <div className="panShadow-unique"></div>
          </div>
          <div className="progress-wrapper">
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${progressPercentage}%` }}></div>
            </div>
            <div className="timer-seconds">{parseInt(progressPercentage)} % gerando dieta</div>
          </div>
        </div>
      )}
    </div>
  );
}
