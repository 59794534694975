import React from 'react';
import { motion } from 'framer-motion';
import { Clipboard, Users, Zap } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col items-center text-center"
    whileHover={{ y: -5 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="bg-green-100 p-3 rounded-full mb-4">
      <Icon className="w-6 h-6 text-green-600" />
    </div>
    <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
    <p className="text-sm text-gray-600 leading-relaxed">{description}</p>
  </motion.div>
);

const Features = () => {
  return (
    <section className="bg-black py-12 sm:py-20 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-8 text-white">
          Recursos Poderosos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
          <FeatureCard
            icon={Clipboard}
            title="Planos Personalizados"
            description="Crie dietas únicas para cada cliente com facilidade e precisão."
          />
          <FeatureCard
            icon={Users}
            title="Gerenciamento de Clientes"
            description="Acompanhe o progresso e histórico de cada paciente em um só lugar."
          />
          <FeatureCard
            icon={Zap}
            title="Rápido e Eficiente"
            description="Economize tempo com nossa interface intuitiva e recursos automatizados."
          />
        </div>
      </div>
    </section>
  );
};

export default Features;