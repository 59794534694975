import React, { useState } from 'react';
import '../../Styles/Desafio.css';
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import mercadopago from '../../../img/mercado-pago-logo.png'


function OrderBumpOverlay({ onConfirm, onCancel }) {
  return (
    <div className="overlay">
      <div className="order-bump-modal">
        <h2 className="text-xl font-bold text-center mb-2">Incluir Treino na Dieta?</h2>
        <p className="text-center text-green-600 mb-2">Por apenas R$ 3,99 a mais!</p>
        <ul className="space-y-2 mb-4">
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=💪" alt="flexed bicep" />
            <span className='text-black text-sm'>Treinos personalizados</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🥗" alt="salad bowl" />
            <span className='text-black text-sm'>Receitas fit, Lista de substituições</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🛒" alt="shopping cart" />
            <span className='text-black text-sm'>Lista de compras dos alimentos</span>
          </li>
         
        </ul>
        <div className="flex justify-around">
          <button onClick={onConfirm} className="bg-green-500 text-white font-bold py-2 px-4 rounded">Sim</button>
          <button onClick={onCancel} className="text-red-500 font-bold py-2 px-4 rounded">Não</button>
        </div>
      </div>
    </div>
  );
}


export default function JejumIntermitente() {
  const [addOrderBump, setAddOrderBump] = useState(false);
  const [amout, setAmount] = useState(15.99);
  const link = 'https://pay.kiwify.com.br/KzvNXjS'
  const navigate = useNavigate();
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true);


  const handleChangeOrderBump = () => {
    const baseAmount = 15.99;
    const extraAmount = 3.99;

    setAddOrderBump(!addOrderBump);

    if (!addOrderBump) {
      setAmount(baseAmount + extraAmount);
    } else {
      setAmount(baseAmount);
    }
  };



  const handlePaymentWaiting = () => {
    // Precisa ser melhor planejado/elaborado
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa pagina!')

  };


  const handlePaymentSuccess = () => {
    navigate('/comprarealizada');
  };

  const handleConfirmOrderBump = () => {
    setAddOrderBump(true);
    setShowOrderBumpOverlay(false);
    handleChangeOrderBump(); // Atualiza o valor do pedido
  };

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false);
  };

  return (
    <div className="payment-page">
       {showOrderBumpOverlay && (
        <OrderBumpOverlay
          onConfirm={handleConfirmOrderBump}
          onCancel={handleCancelOrderBump}
        />
      )}
      <div className='header-div'>
        <img src={mercadopago} alt='Logo Mercado Pago' />
      </div>  
      <div className="payment-method-selector">
      <span className="bg-green-100 text-green-600 text-xs font-semibold px-2.5 py-0.5 rounded">Treinos e PDF inclusos</span>
        <SubscriptionComponent
          amount={amout}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentWaiting={handlePaymentWaiting}
          addOrderBump={addOrderBump}
          linkexterno={link}
        />
      </div>
  
    </div>
  );
  
  }  