import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { UserAuth } from '../../context/AuthContext';


export default function CardHorario({ id, message, onClose, nome, numero, horario }) {
    const horarios = ['12:00', '12:20', '12:40', '13:00','13:20', '13:40','14:00', '14:10', '14:20','14:40','15:00','15:20','15:40','16:00','16:10','16:20','16:40','17:00']
    const { MarcarHorario } = UserAuth()
   
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState('')
    const [hora, setHora] = useState('')
    const overlayStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fundo escurecido
        zIndex: '999', // Garante que fique atrás do cardStyle
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    const cardStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 500,
        padding: '20px',
        textAlign: 'center',
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
        marginRight: '10px' ,
        marginLeft: '10px' ,
        transition: 'background-color 0.3s ease',
    };

    const buttonStyle2 = {
        padding: '10px 20px',
        backgroundColor: '#f01e2c',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
        marginRight: '10px' ,
        marginLeft: '10px' ,
        transition: 'background-color 0.3s ease',
    };

    const iconStyle = {
        color: '#FFCC00',
        marginBottom: '20px',
    };

    const closeButtonStyle = {
        position: 'relative',
        left: '190px',
        background: 'none',
        border: 'none',
        color: '#aaa',
        cursor: 'pointer',
    };


    const handleMarcar = (value, data) => { 
        setIsVisible(true)
        console.log(isVisible)
        setData(data)
        setHora(value)
    }

    const handleSubmitHorario = () => {
        try{
            MarcarHorario(data, hora, nome, numero)
        }catch {
            toast.error('Horario ja marcado!')
        }finally{
            onClose()
        }
    }

    return (<>
       
            {isVisible && ( <div style={overlayStyle}> <div style={cardStyle} className=' z-[1000]'>
                <div>
                    <FontAwesomeIcon icon={faExclamationCircle} size="3x" style={iconStyle} />
                    <h3>Agendamento</h3>
                    <h5>{`Confirmação de agendamento para dia ${data} as ${hora}`}</h5>
                </div>
                <div className='flex justify-around'>
                    <button style={buttonStyle} onClick={() => handleSubmitHorario()}>Sim</button>
                    <button style={buttonStyle2} onClick={()=>setIsVisible(false)}>Não</button>
                </div>
            </div> </div>)}
            
            <div>
        <div className='my-4'>   
            <div className='text-gray-600'>
            <button onClick={onClose} style={closeButtonStyle}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x" style={iconStyle} />
                <h4>Agende seu Horário! </h4>
                <h6>Data: {message} </h6>
                <p>Por favor, escolha com atenção o horário do seu atendimento via WhatsApp</p>
                <div className='grid grid-cols-2'>
                
                {
  horarios.map((value, index) => {
  
    const isDisabled = horario && (value in horario); // Verifica se o valor está no objeto horario
    
    return (
      <button 
        key={index}
        style={{
          ...buttonStyle,
          background: isDisabled ? 'gray' : '#4CAF50', // Cor do fundo muda se desabilitado
          cursor: isDisabled ? 'not-allowed' : 'pointer' // Cursor muda se desabilitado
        }}
        onClick={() => !isDisabled && handleMarcar(value, message)} // Só chama a função se não desabilitado
        disabled={isDisabled} // Desabilita o botão se o horário já estiver em horario
      >
          {isDisabled ? "Ocupado" : value}
      </button>
    );
  })
}
                
                
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
    