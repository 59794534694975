import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component'
import mercadopago from '../../../img/mercado-pago-logo.png'
import { Dumbbell, ShoppingCart, Salad, CreditCard, LucideActivity } from 'lucide-react'

function OrderBumpOverlay({ onConfirm, onCancel }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-[95%] sm:max-w-lg md:max-w-xl">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4">Incluir Treino na Dieta?</h2>
        <p className="text-center text-green-600 font-semibold mb-4">Por apenas R$ 3,99 a mais!</p>
        <ul className="space-y-4 mb-6">
          {[
            { icon: Dumbbell, text: 'Treinos personalizados' },
            { icon: CreditCard, text: 'Recomendação Whey e creatina' },
            { icon: Salad, text: 'Receitas fit, Lista de substituições' },
            { icon: ShoppingCart, text: 'Lista de compras dos alimentos' },
          ].map((item, index) => (
            <li key={index} className="flex items-center">
              <div className="rounded-full p-2 mr-3 bg-gray-100">
                <item.icon className="w-5 h-5 sm:w-6 sm:h-6 text-gray-800" />
              </div>
              <span className="text-gray-700 text-sm sm:text-base">{item.text}</span>
            </li>
          ))}
        </ul>
        <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            onClick={onConfirm}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 w-full sm:w-auto"
          >
            Sim, quero!
          </button>
          <button
            onClick={onCancel}
            className="text-gray-600 hover:text-gray-800 font-bold py-2 px-6 rounded-full transition duration-300 border border-gray-300 w-full sm:w-auto"
          >
            Não, obrigado
          </button>
        </div>
      </div>
    </div>
  )
}

export default function DefinicaoMassa() {
  const [addOrderBump, setAddOrderBump] = useState(false)
  const [amount, setAmount] = useState(16.99)
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true)
  const link = 'https://pay.kiwify.com.br/C9VNOiT'
  const navigate = useNavigate()


  const handleChangeOrderBump = () => {
    const baseAmount = 16.99
    const extraAmount = 3.98
    setAddOrderBump(true)
    setAmount(!addOrderBump ? baseAmount + extraAmount : baseAmount)
  }

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!')
  }

  const handlePaymentSuccess = async () => {
    navigate('/comprarealizada')
  }

  const handleConfirmOrderBump = () => {
    setAddOrderBump(true)
    setShowOrderBumpOverlay(false)
    handleChangeOrderBump()
  }

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false)
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {showOrderBumpOverlay && (
        <OrderBumpOverlay onConfirm={handleConfirmOrderBump} onCancel={handleCancelOrderBump} />
      )}

      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <img 
              src={mercadopago} 
              alt="Logo Mercado Pago" 
              className="h-auto max-h-16 w-auto max-w-full object-contain"
            />
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-lg mx-auto">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">Finalizar Compra</h1>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">Resumo do Pedido</h2>
            <div className="flex justify-between items-center">
              <span className="text-gray-600 flex items-center">
                <LucideActivity className="w-5 h-5 mr-2 text-green-500" />
                Definição e Massa
                {addOrderBump && (
                  <span className="ml-2 inline-block bg-green-50 text-green-500 text-xs font-semibold px-2 py-1 rounded-full">
                    + Adicional
                  </span>
                )}
              </span>
            </div>
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-gray-800">Total</span>
                <span className="text-lg font-bold text-green-600">R$ {amount.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="payment-method-selector">
            <SubscriptionComponent
              amount={amount}
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentWaiting={handlePaymentWaiting}
              addOrderBump={addOrderBump}
              linkexterno={link}
            />
          </div>
        </div>
      </main>

      <footer className="bg-white text-gray-900 py-4">
        <div className="container mx-auto px-4 text-center text-sm">
          &copy; 2024 Nutri Inteligente. Todos os direitos reservados.
        </div>
      </footer>
    </div>
  )
}