import React, { useState } from 'react';
import './ListaCompras.css';
import generatePDF from './generatePDF';
import HamburgerMenuLista from './Menu/MenuLista';



const ListaCompras = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [dietPlan, setDietPlan] = useState('');
    const [shoppingList, setShoppingList] = useState({ proteínas: [], carboidratos: [], açúcares: [], frutas: [] });
    const [items, setItems] = useState('')
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    const categories = {
        proteínas: [
          'frango', 'queijo', 'carne', 'ovo','ovos','peixe', 'requeijão', 'atum', 'peru', 
          'queijo cottage', 'carne bovina', 'presunto', 'salmão', 'camarão', 'tofu', 
          'soja', 'seitan', 'lombo', 'cordeiro', 'frutos do mar', 'carne - Patinho ou Acém', 
          'carne de porco'
        ],
        carboidratos: [
          'arroz', 'macarrão', 'pão', 'batata', 'tapioca', 'aveia', 'biscoito', 'mandioca', 
          'cuscuz', 'rap10', 'quinoa', 'milho', 'centeio', 'trigo', 'farinha', 'leguminosas', 
          'feijão', 'lentilhas', 'grão de bico', 'cereal'
        ],
        açúcares: [
          'chocolate', 'suco', 'mel', 'geleia'
        ],
        frutas: [
          'maçã', 'banana', 'pêra', 'abacaxi', 'laranja', 'tomate', 'manga', 'uva', 'melancia', 
          'figo', 'morango', 'kiwi'
        ]
    };

    const normalizeFood = (food) => {
      food = food.toLowerCase().replace(/s\b|,|\./g, '').trim();
      if (food.includes('carne') && !food.includes('porco')) {
          return 'carne - Patinho ou Acém';
      } else if (food.includes('arroz integral')) {
          return 'arroz';
      } else if (food.includes('iogurte natural desnatado')) {
          return 'iogurte';
      } else if (food.includes('cereal integral')) {
          return 'cereal';
      } else if (food.includes('carne de porco')) {
          return 'carne de porco';
      } else if (food.includes('peito de frango')) {
          return 'frango';
      } else if (food.includes('whey protein')) {
          return 'whey protein';
      } else if (food.includes('ovos') || food.includes('ovo')) {
          return 'ovo';
      } else if (food.includes('requeijao')) {
          return 'requeijão';
      }
      return food;
  };
  
  const extractFoodItems = (text) => {
    const foodLists = { proteínas: new Set(), carboidratos: new Set(), açúcares: new Set(), frutas: new Set() };

    // Remove os números, asteriscos e espaços no final do texto
    text = text.replace(/[\d:]+[*]*\**$/, '').trim();

    // Expressão regular para capturar os itens de alimentos
    const matches = text.matchAll(/([^(\n]+)\s*(\(\d+g|\d+ml|\d+\s*cal\))?/g);

    for (const match of matches) {
        if (!match[1].toLowerCase().includes('total:')) {
            // Separa os itens por '+'
            let items = match[1].split('+').map(item => item.trim());
            items.forEach(item => {
                let foodComponent = item.split('(')[0].trim();
                foodComponent = normalizeFood(foodComponent);
                Object.keys(categories).forEach(category => {
                    categories[category].forEach(food => {
                        if (foodComponent.includes(food) && !foodLists[category].has(food)) {
                            foodLists[category].add(food);
                        }
                    });
                });
            });
        }
    } 
      return {
          proteínas: Array.from(foodLists.proteínas),
          carboidratos: Array.from(foodLists.carboidratos),
          açúcares: Array.from(foodLists.açúcares),
          frutas: Array.from(foodLists.frutas)
      };
  };
   
    
    const handleGenerateList = () => {
        const items = extractFoodItems(dietPlan);
        setItems(items)
        setShoppingList(items);
    };

    const handleDownloadPdf = () => {
        generatePDF(shoppingList);
    };


    return (<div>
        <header className='m-3'>
        <HamburgerMenuLista isOpen={isMenuOpen} toggleMenu={toggleMenu}></HamburgerMenuLista>
        </header>
        <div className="listaCompras-container">
          <h1> Lista de Compras 🛒</h1>
          <div className="textarea-container">
            <textarea
              rows="10"
              cols="80"
              value={dietPlan}
              onChange={(e) => setDietPlan(e.target.value)}
              placeholder="Cole sua dieta aqui..."
            />
          </div>
          <div className="button-container">
            <button onClick={handleGenerateList}>Gerar Lista de Compras</button>
            <button onClick={handleDownloadPdf}>Baixar PDF</button>

          </div>
          <br/>
          {items && (<div>
          <h2>Itens Necessários 🥗</h2>
          <div id="listaCompras-cardContainer" className="listaCompras-cardContainer">
            {Object.keys(shoppingList).map((category) => (
              <div key={category}>
                <h3>{category.charAt(0).toUpperCase() + category.slice(1)}:</h3>
                {shoppingList[category].map((item, index) => (
                  <div key={index} className="listaCompras-card">
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        )}
        </div>
        </div>
      );      
  };
  
export default ListaCompras;
