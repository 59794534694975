import { jsPDF } from "jspdf";

const generatePDF = (items) => {
  const doc = new jsPDF();

  doc.setFontSize(18);
  doc.setTextColor(60, 143, 83);  // Cor verde escura para o título
  doc.text("Lista de Compras", 14, 20);

  doc.setFontSize(10);
  doc.setTextColor(150, 150, 150); // Cor cinza para texto secundário
  doc.text("Recomendações de mercados mais baratos:", 14, 30);
  doc.text("Atacadão - Por ser um mercado de atacado o preços dos alimentos é bem acessível.", 14, 40);
  doc.text("Empório Brasil - Costuma ter promoções de ótimo custo.", 14, 50);
  doc.text("SuperMarket - Bons preços e boas ofertas.", 14, 60);

  let yPos = 70; // Inicializa a posição Y para a lista de compras após as recomendações

  Object.keys(items).forEach(category => {
    if (yPos > 280) {
      doc.addPage();
      yPos = 10; // Recomeça no topo da nova página
    }

    doc.setFontSize(16);
    doc.setTextColor(63, 81, 181); // Cor azul para categorias
    doc.text(`${category.charAt(0).toUpperCase() + category.slice(1)}:`, 14, yPos);
    yPos += 10;

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Cor preta para itens
    items[category].forEach((item, index) => {
      if (yPos > 280) {
        doc.addPage();
        yPos = 10; // Recomeça no topo da nova página
      }
      doc.text(`${index + 1}. ${item}`, 14, yPos);
      yPos += 10;
    });

    yPos += 10; // Adiciona um espaço extra entre categorias
  });

  // Salva o PDF
  doc.save("NI_Lista-Compras.pdf");
}

export default generatePDF;
