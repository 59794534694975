import React from 'react'
import {  Instagram } from 'lucide-react'

export default function Footer() {
  return (
    <footer className="bg-black text-white py-12 mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-3 gap-8">
          <div>
            <h4 className="text-lg font-semibold mb-4">Nutri Inteligente PRO</h4>
            <p className="text-gray-300">Transformando a nutrição com tecnologia avançada.</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Parceria</h4>
            <p className="text-gray-300">
              Em parceria com a Nutri Inteligente, o maior site de dietas do Brasil.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Siga-nos</h4>
            <div className="flex space-x-4">
              <Instagram className="w-6 h-6" />
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
          <p>&copy; 2024 Nutri Inteligente. Todos os direitos reservados.</p>
          <p className="mt-2">Desenvolvido com ❤️ para profissionais de saúde.</p>
        </div>
      </div>
    </footer>
  )
}