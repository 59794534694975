import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import LoadComponent from "../LoadingComponent/LoadComponent";

function Obrigado() {
 
    return (
        <>
            <Helmet>
                <title>Obrigado - Pagamento Concluído</title>
                <script>
                    {`
                        gtag('event', 'conversion', {
                            'send_to': 'AW-16611094396/JBSVCLqd48IZEPze5PA9',
                            'transaction_id': ''
                        });
                    `}
                </script>
            </Helmet>

            <div className="flex flex-col items-center justify-start min-h-screen bg-gray-50 p-5">
                <FontAwesomeIcon icon={faCheckCircle} color="#4CAF50" size="3x" className="mb-4" />
                <h1 className="text-3xl font-bold text-green-500 mb-4">Pagamento Concluído</h1>
              
                <LoadComponent></LoadComponent>
            </div>
        </>
    );
}

export default Obrigado;
