export default function ComoMontar() {
    return (
      <div className="text-center p-4 bg-white rounded-2xl shadow-lg" style={{ width: '95%', margin: '0 auto', border: '1.3px solid #D9D8D8' }}>
        <div className="flex justify-center mb-4">
          <div className="w-14 h-14 rounded-full bg-green-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-8 h-8 text-green-600"
            >
              <path d="M12 20h9" />
              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-bold  mb-4">Como Montar?</h2>
        <p className="text-gray-600 text-sm mb-4">
          Você precisa preencher todas suas informações acima como peso, altura, idade, preferências alimentares etc. e logo após clique em <strong> Montar Minha Dieta </strong> <br>
        </br>
        </p>

      </div>
    )
  }