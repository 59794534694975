import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserAuth } from '../../../context/AuthContext'
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component'
import mercadopago from '../../../img/mercado-pago-logo.png'

export default function DietaConsulta() {
  const addOrderBumpT = true
  const baseAmount = 29.99
  const link = 'https://pay.kiwify.com.br/g4CGZlP'
  const { atendimentoNutricionista } = UserAuth()
  const navigate = useNavigate()

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!')
  }

  const handlePaymentSuccess = () => {
    navigate('/comprarealizada')
    atendimentoNutricionista(true, true)
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <img 
              src={mercadopago} 
              alt="Logo Mercado Pago" 
              className="h-auto max-h-16 w-auto max-w-full object-contain"
            />
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-lg mx-auto">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">Finalizar Compra</h1>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">Resumo do Pedido</h2>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Plano Dieta e Consulta</span>
              <span className="font-medium">R$ {baseAmount.toFixed(2)}</span>
            </div>
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-gray-800">Total</span>
                <span className="text-lg font-bold text-green-600">R$ {baseAmount.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="payment-method-selector">
            <SubscriptionComponent
              amount={baseAmount}
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentWaiting={handlePaymentWaiting}
              addOrderBump={addOrderBumpT}
              linkexterno={link}
            />
          </div>
        </div>
      </main>

      <footer className="bg-white text-gray-900 py-4">
        <div className="container mx-auto px-4 text-center text-sm">
          &copy; 2024 Nutri Inteligente. Todos os direitos reservados.
        </div>
      </footer>
    </div>
  )
}