import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../Banner/Banner.css'; // Este é o arquivo CSS que você irá criar

const Banner = () => {
  return (
    <div className="banner">
      <Link to="/home" className="banner-button">
      Preencha suas medidas abaixo
        <FontAwesomeIcon icon={faArrowDown} />
      </Link>
      <div>     
      </div>
    </div>
  );
};

export default Banner;