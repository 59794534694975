import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ title, price, features, buttonText, link, topMargin, topo }) {
  return (
    
    <div className={`max-w-sm mx-auto bg-card text-card-foreground p-6 rounded-lg shadow-lg ${topMargin ? 'mt-1' : 'mt-3'}`}
    style={{ border: '1px solid #D9D8D8' }}>
      <div className="text-center">
        <span className="bg-green-100 text-green-600 text-xs font-semibold px-2.5 py-0.5 rounded">{topo ? topo : 'Economize 20%'}</span>
        <div className="text-2xl mt-1 font-bold">{title}</div>
        <div className="text-xl font-semibold mt-2"><span className="text-4xl font-bold">{price}</span></div>
      </div>
      <ul className="mt-6 space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="icon">{feature.icon}</span>
            <span className="ml-2" style={{ color: '#4d4d4d', fontSize: '0.9rem' }}>{feature.text}</span>
            </li>
        ))}

      </ul>
      <Link to={link} className="mt-6 w-full py-2 rounded-lg text-center text-white" style={{
        backgroundColor: '#17A34A',
        display: 'block',
        textDecoration: 'none'
      }}>
        {buttonText}
      </Link>
    </div>
  );
}

const svgIcon = (
  <svg height="22" width="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#D1FAE5"></circle>
    <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
  </svg>
);

const features = [
  { icon: svgIcon, text: 'Dieta Personalizada' },
  { icon: svgIcon, text: 'Baseada em seus alimentos favoritos' },
  { icon: svgIcon, text: 'Calorias ajustadas individualmente' },
  { icon: svgIcon, text: 'Alinhada aos seus objetivos' },
];



const featuresDietaConsulta = [
  { icon: svgIcon, text: 'Dieta personalizada pela nutricionista' },
  { icon: svgIcon, text: 'Flexibilidade para ajustar sua dieta' },
  { icon: svgIcon, text: 'Variedade de opções de refeições' },
  { icon: svgIcon, text: 'Adaptada à sua rotina diária' },
  { icon: svgIcon, text: 'Acompanhamento contínuo' },
  { icon: svgIcon, text: 'Consulta feita pelo Whatsapp' },
  { icon: svgIcon, text: 'Resultados mais rápidos' },
  { icon: svgIcon, text: 'Dicas da Nutricionista' },
  { icon: svgIcon, text: 'Treinos Personalizados' },
];


const featurestreino = [
  { icon: svgIcon, text: 'Dieta Personalizada' },
  { icon: svgIcon, text: 'Treinos Personalizados' },
  { icon: svgIcon, text: 'Opções para casa ou academia' },
  { icon: svgIcon, text: 'Baseado em seus alimentos e objetivos' },
  { icon: svgIcon, text: 'Calorias e intensidade ajustadas' },
];


function PlanosNutri() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-center my-4">
    <h1 className="text-2xl font-bold text-foreground mb-0">Escolha o Plano Ideal</h1>
    <p className="text-gray-600 text-sm mt-0 ">Preços que cabem no seu bolso!</p>
<div className="flex flex-wrap justify-center gap-4">
<ProductCard 
          title="Sua Dieta + Treino 🏋️‍♂️" 
          price="R$ 11,99" 
          topo="Mais Vendido"
          features={featurestreino} 
          buttonText="Comprar Agora" 
          link="/dietatreino"
        />
        <ProductCard 
          title="Dieta para Emagrecer 🔥" 
          topo="Dieta focada em Emagrecimento"
          price="R$ 9,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/emagrecer"
          topMargin={true}
        />
         <ProductCard 
          title="Acompanhamento com a Nutricionista" 
          topo="O mais completo"
          price="R$ 29,99" 
          features={featuresDietaConsulta} 
          buttonText="Comprar Agora" 
          link="/dieta3meses"
        />  
        <ProductCard 
          title="Emagrecer + Massa 💪" 
          price="R$ 10,99" 
          topo="Para emagrecer e Definir"

          features={features} 
          buttonText="Comprar Agora" 
          link="/definicao"
        />
         <ProductCard 
          title="Ganho de Massa " 
          topo="Para ganho de massa"
          price="R$ 14,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/massamuscular"
        />
      <ProductCard 
          title="Definição + Massa" 
          topo="Para Definição e ganho de massa"
          price="R$ 16,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/definicaomassa"
        />

      </div>
     
    </div>
  );
}

export default PlanosNutri;